import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import theme from "theme";

const PhotoSliderWrapper = styled.div`
   .slick-dots {
      li {
         button {
            background-color: ${theme.colors.white};
         }
      }
   }
`;

const SingleSlide = styled.div`
   margin: 0 15px;
   padding: 0 15px;
   object-fit: contain;
   height: 360px;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const Image = styled.img`
   display: block;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   border-radius: 4px;
`;

const PhotoSlider = ({ photos }) => {
   const photoSlider = {
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      draggable: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      pauseOnHover: false,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2,
            },
         },
         {
            breakpoint: 769,
            settings: {
               slidesToShow: 1,
            },
         },
      ],
   };
   return (
      <PhotoSliderWrapper>
         <Slider {...photoSlider}>
            {photos.map((photo, index) => {
               return (
                  <div key={index}>
                     <SingleSlide>
                        <Image src={photo.slide?.sourceUrl} alt="" className="img-fluid" />
                     </SingleSlide>
                  </div>
               );
            })}
         </Slider>
      </PhotoSliderWrapper>
   );
};

export default PhotoSlider;
