import "styles/pages/page-mission-vision.scss";

import React from "react";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

import TitleWithLine from "components/TitleWithLine";
import LogoShowcase from "components/LogoShowcase";
import PhotoSlider from "components/PhotoSlider";
import Banner from "components/Banner";
import WhiteTile from "components/WhiteTile";

const MissionVision = ({ pageContext }) => {
  const mv = pageContext.pageContent;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/mission-vision-img-bg.png").default}
      />

      <section className="section-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-mission__inner">
                <TitleWithLine variantX="left" variantY="bottom">
                  {mv.missionTitle}
                </TitleWithLine>
                <p className="section-mission__text">{mv.missionText}</p>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={mv.missionImage?.sourceUrl}
                className="img-fluid section-mission__img"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-interpretation">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-12">
              <img
                src={mv.easyDataImage?.sourceUrl}
                className="img-fluid section-interpretation__img"
                alt=""
              />
            </div>
            <div className="col-lg-6 order-12 order-lg-1">
              <div className="section-interpretation__inner">
                <TitleWithLine variantX="left" variantY="bottom">
                  {mv.easyDataTitle}
                </TitleWithLine>
                <p className="section-interpretation__text">
                  {mv.easyDataText}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-certificate">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-certificate__inner">
                <TitleWithLine variantX="left" variantY="bottom">
                  {mv.certificateTitle}
                </TitleWithLine>
                <p className="section-certificate__text">
                  {mv.certificateText}
                </p>
                <LogoShowcase logoImgs={mv.certificateLogos} />
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={require("assets/images/mission-vision-img-3.jpg").default}
                className="img-fluid section-certificate__img"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-photo-slider">
        <div className="container">
          <PhotoSlider photos={mv.dividerSlider} />
        </div>
      </section>
      <section className="section-vision">
        <Banner
          title={mv.divider1Title}
          description={mv.divider1Text}
          size="small"
        />
      </section>
      <section className="section-nav-tiles">
        <div className="container">
          <div className="row justify-content-center">
            {mv.navTiles.map((item, index) => {
              return (
                <div className="col-sm-4" key={index}>
                  <Link to={item.tileHref}>
                    <WhiteTile variant="center" border="bordered">
                      {item.tileText}
                    </WhiteTile>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MissionVision;
