import React from "react";
import styled from "styled-components";
import theme from "theme";

const SingleSlide = styled.div`
  border: 1px solid #e5ebf8;
  border-radius: 5px;
  background-color: #fff;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 15px;
  padding: 15px 15px;
  transition: all 0.25s ease-in-out;

  ${theme.mixins.onEvent} {
    transform: scale(1.05);
    box-shadow: ${theme.shadows.shadow};
  }
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const LogoShowcase = ({ logoImgs }) => {
  return (
    <div className="logo-showcase">
      <div className="row g-0">
        {logoImgs.map((logo, index) => {
          return (
            <div key={index} className="col-md-4 logo-showcase__item">
              <SingleSlide>
                <Image
                  src={logo.logo?.sourceUrl}
                  alt=""
                  className="img-fluid"
                />
              </SingleSlide>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LogoShowcase;
